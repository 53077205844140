import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800.000000 800.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)">
          <path d="M3645 7989 c-579 -58 -1116 -225 -1595 -496 -1107 -627 -1840 -1708
-2011 -2966 -26 -194 -37 -651 -20 -848 60 -690 279 -1320 654 -1884 581 -873
1470 -1479 2491 -1700 142 -31 338 -60 496 -74 148 -14 542 -14 690 0 1176
106 2222 704 2913 1665 349 485 596 1087 691 1684 45 280 60 707 35 980 -62
685 -287 1323 -664 1880 -652 965 -1643 1582 -2798 1741 -122 17 -215 22 -472
24 -176 2 -360 -1 -410 -6z m720 -260 c864 -84 1666 -463 2283 -1079 824 -823
1217 -1977 1066 -3134 -158 -1222 -927 -2301 -2034 -2856 -666 -334 -1421
-461 -2164 -364 -811 105 -1568 479 -2154 1064 -1155 1153 -1429 2914 -678
4365 654 1266 1986 2056 3401 2019 99 -3 225 -9 280 -15z"/>
<path d="M3835 7624 c-192 -14 -319 -28 -443 -49 -1294 -219 -2382 -1143
-2810 -2385 -136 -397 -195 -757 -196 -1190 0 -248 11 -375 50 -608 216 -1290
1143 -2383 2384 -2810 397 -136 759 -196 1185 -196 334 1 597 33 898 110 1282
330 2281 1329 2611 2611 77 301 110 567 110 903 -1 328 -33 594 -110 893 -375
1460 -1602 2527 -3106 2702 -127 14 -478 26 -573 19z m765 -520 c224 -9 537
-53 823 -116 l107 -23 0 -512 0 -513 -240 0 c-132 0 -240 2 -240 4 0 3 -16 57
-35 120 -108 357 -271 542 -540 612 -55 15 -108 19 -240 19 -193 0 -266 -15
-398 -81 -85 -43 -198 -151 -240 -231 -63 -118 -85 -313 -52 -468 8 -38 11
-72 7 -76 -4 -4 -43 -17 -87 -30 -110 -31 -284 -102 -372 -150 -163 -90 -341
-242 -435 -371 -23 -32 -46 -54 -50 -50 -16 16 -59 147 -80 244 -18 84 -22
135 -22 283 0 192 11 269 61 430 137 434 528 740 1098 858 294 61 479 71 935
51z m1733 -578 c23 -13 80 -60 127 -104 l85 -80 -41 -44 c-63 -67 -336 -362
-346 -373 -10 -12 -160 133 -199 194 -45 69 -40 112 16 168 41 42 48 45 89 42
l44 -4 -14 27 c-29 56 -13 117 44 172 54 50 113 51 195 2z m397 -436 c44 -22
113 -103 141 -165 10 -22 19 -61 19 -86 0 -71 -42 -124 -174 -222 -175 -130
-213 -157 -222 -157 -9 1 -71 81 -79 102 -3 8 50 53 127 108 166 120 198 149
198 185 0 38 -28 82 -62 96 -37 15 -79 -6 -243 -126 -71 -52 -133 -95 -137
-95 -11 0 -86 104 -82 112 3 4 68 53 146 110 218 158 282 182 368 138z m-1910
-295 c203 -22 592 -90 693 -121 16 -5 17 -38 17 -515 l0 -509 -240 0 -239 0
-16 58 c-114 409 -276 603 -561 674 -125 31 -362 31 -479 -1 -243 -64 -399
-216 -451 -437 -24 -104 -15 -293 20 -400 63 -196 230 -366 548 -557 42 -26
168 -96 280 -155 425 -228 621 -365 819 -568 186 -193 291 -391 346 -654 28
-131 25 -489 -5 -622 -59 -269 -160 -458 -341 -639 -222 -221 -504 -354 -906
-426 -144 -26 -772 -25 -1025 0 -315 32 -532 64 -747 109 l-103 21 0 544 0
543 240 0 240 0 6 -27 c63 -283 185 -516 335 -639 70 -58 179 -109 284 -136
77 -19 116 -22 270 -22 205 0 280 15 414 81 145 71 262 221 308 393 26 97 23
338 -5 441 -81 297 -279 477 -857 781 -591 311 -862 545 -1035 893 -104 208
-145 439 -122 692 38 424 231 736 591 952 216 130 538 225 886 260 135 14 664
5 835 -14z m2213 -244 c43 -17 99 -74 123 -126 25 -54 32 -143 14 -189 -9 -24
-12 -25 -62 -19 -29 3 -57 9 -61 13 -5 4 -4 26 2 48 15 52 -4 110 -40 131 -24
13 -29 13 -54 -3 -28 -18 -28 -19 -22 -101 7 -103 -4 -145 -47 -183 -128 -112
-293 -22 -313 172 -6 63 -8 61 75 71 l52 6 0 -54 c0 -61 27 -107 61 -107 29 0
37 33 32 135 -4 76 -1 96 15 125 36 66 154 108 225 81z m-2861 -305 c51 -29
161 -89 243 -133 177 -95 278 -154 397 -231 l86 -57 26 -85 c14 -47 31 -109
38 -137 l11 -53 125 0 124 0 39 -42 c64 -70 168 -231 209 -325 48 -109 85
-243 101 -369 17 -136 6 -421 -20 -539 -21 -93 -46 -178 -57 -196 -3 -6 -31
27 -62 73 -66 98 -264 299 -377 384 -176 133 -331 228 -607 375 -655 348 -821
525 -820 879 1 167 41 269 146 368 57 54 149 104 234 127 67 19 62 20 164 -39z
m3150 -301 c10 -33 18 -62 18 -65 0 -3 -95 -32 -211 -65 l-211 -60 6 -30 c3
-16 9 -42 12 -57 6 -24 3 -27 -49 -42 -30 -9 -59 -16 -64 -16 -9 0 -103 313
-103 342 0 17 110 53 120 40 4 -4 12 -26 19 -49 8 -30 17 -43 29 -42 9 0 98
24 197 54 99 29 189 53 199 52 15 -2 24 -17 38 -62z m73 -337 c4 -35 7 -67 8
-73 1 -5 -20 -20 -46 -32 -26 -13 -47 -26 -47 -30 1 -23 30 -196 33 -200 3 -2
27 -7 54 -11 l48 -7 11 -75 c6 -41 9 -76 8 -78 -2 -3 -586 108 -595 114 -7 4
-33 184 -27 189 10 9 525 264 535 264 7 1 14 -24 18 -61z m-80 -639 c53 -15
108 -74 125 -133 15 -54 8 -130 -16 -178 -21 -43 -63 -98 -73 -98 -5 0 -29 16
-55 36 l-48 36 25 27 c35 37 47 64 47 105 0 40 -21 66 -55 66 -30 0 -57 -28
-74 -77 -36 -102 -84 -143 -169 -143 -110 0 -174 69 -174 187 0 48 6 76 27
118 30 61 31 62 108 23 l38 -19 -21 -29 c-26 -37 -36 -91 -21 -119 25 -46 74
-13 100 67 8 23 27 57 44 78 50 60 108 76 192 53z m-4420 -569 c20 -21 38 -62
62 -142 78 -255 216 -456 377 -549 56 -32 169 -71 261 -90 89 -19 333 -16 428
5 l79 17 54 -42 c106 -81 207 -210 246 -311 21 -56 29 -51 -124 -75 -156 -25
-700 -24 -968 1 -252 23 -523 61 -715 99 l-160 32 -3 543 -2 542 218 0 218 0
29 -30z m4505 18 c-1 -7 -6 -40 -13 -73 l-13 -60 -51 -5 c-29 -3 -54 -8 -56
-10 -2 -3 -12 -43 -21 -90 -10 -47 -19 -92 -22 -100 -3 -11 11 -23 41 -38 27
-13 45 -28 45 -39 0 -34 -24 -133 -33 -133 -6 0 -434 253 -506 299 -12 8 -11
22 4 98 10 48 20 90 21 92 4 4 520 68 567 70 24 1 37 -3 37 -11z m-201 -696
c11 -8 7 -21 -20 -71 -19 -33 -41 -63 -49 -65 -8 -3 -96 -17 -195 -31 -99 -14
-188 -29 -197 -33 -13 -5 26 -29 137 -86 l154 -79 -31 -61 c-17 -34 -32 -62
-33 -64 -4 -4 -475 232 -499 250 -17 14 -17 17 13 75 17 34 39 64 48 66 10 3
105 18 211 33 105 15 192 30 192 33 0 3 -68 41 -150 84 l-150 78 32 63 31 64
246 -123 c135 -68 252 -128 260 -133z m-586 -516 l226 -158 -40 -60 -41 -59
-26 17 c-15 9 -123 84 -240 166 l-212 150 41 59 c27 37 46 56 54 51 6 -4 113
-78 238 -166z m-176 -290 l173 -161 -52 -52 -52 -53 -61 58 c-33 32 -70 66
-83 77 l-23 19 -55 -55 c-89 -86 -89 -80 7 -170 l84 -77 -52 -53 -51 -52 -211
198 -211 197 47 52 c25 28 48 53 49 54 1 2 39 -32 84 -75 l83 -78 66 73 67 73
-80 79 -81 79 49 50 49 50 41 -36 c23 -20 119 -109 213 -197z m-650 -275 c16
-61 10 -71 -39 -71 -35 0 -90 -46 -86 -73 6 -38 30 -42 109 -19 86 26 129 28
168 8 60 -31 111 -114 111 -182 0 -41 -51 -123 -98 -157 -58 -42 -101 -57
-162 -57 -47 0 -47 0 -54 38 -3 20 -6 49 -6 65 0 26 2 27 53 27 112 0 169 86
86 131 -12 6 -37 3 -81 -11 -117 -37 -179 -23 -233 50 -54 73 -38 169 41 239
43 38 122 72 158 68 15 -1 23 -15 33 -56z"/>
<path d="M6227 6402 c-10 -10 -17 -29 -17 -41 0 -25 46 -91 64 -91 11 0 86 82
86 94 0 12 -76 56 -96 56 -11 0 -28 -8 -37 -18z"/>
<path d="M6097 6212 c-24 -26 -21 -65 6 -90 l23 -22 25 28 c54 59 54 59 17 82
-41 25 -50 25 -71 2z"/>
<path d="M7155 4410 c-22 -10 -71 -33 -110 -51 l-70 -32 105 -18 c154 -26 142
-30 134 44 -3 34 -9 65 -13 68 -3 4 -24 -1 -46 -11z"/>
<path d="M6968 3253 c-32 -2 -58 -7 -58 -11 0 -7 202 -121 207 -118 1 1 9 32
17 69 l14 67 -62 -2 c-33 -1 -87 -3 -118 -5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
